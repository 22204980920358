.about-past-text {
  color: #fff;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 37px;
  padding: 2rem 3.9rem 0.5rem 3.9rem;
}

.container {
  display: flex;
  justify-content: flex-end;
}

.cross-about {
  margin: 2rem 2rem 0 0;
  cursor: pointer;
}

.about-vector {
  margin-left: 33rem;
  margin-top: -2rem;
}

.container-about-past {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 370px;
  scrollbar-width: thin;
  width: 100%;
  background-color: #092948;
}

@media only screen and (max-width: 1440px) {
  .past-back-img {
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 768px) {
  .about-past-text {
    padding: 2rem 2rem 0.5rem 2rem;
  }
  .past-back-img {
    height: 400px;
  }
}

@media only screen and (max-width: 426px) {
  .container-about-past {
    width: 370px;
  }
  .past-back-img {
    height: 550px;
  }
  .about-vector {
    margin-left: 27rem;
  }

  .first_title {
    font-size: 30px !important;
  }
}

@media only screen and (max-width: 376px) {
  .container-about-past {
    width: 330px;
  }
  .first_title {
    font-size: 30px !important;
  }
}

@media only screen and (max-width: 320px) {
  .container-about-past {
    width: 285px;
  }
  .about-vector {
    margin-left: 25rem;
  }

  .first_title {
    font-size: 30px !important;
  }

  .past-back-img {
    height: 550px;
  }
}
