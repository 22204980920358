.menu-collapser {
    position: relative;
    width: auto;
    clear: both;
    margin: 18px 0 18px 10px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.collapse-button {
    position: relative;
    margin: 0;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    float: left;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.collapse-button .icon-bar {
    display: block;
    width: 26px;
    height: 2px;
    margin: 5px 0 0 0;
    background: #fff;
    border-radius: 1px;
}
#mega-menu-holder {
    width: auto;
    margin: 0;
    padding: 0;
    list-style-type: none;
}
#mega-menu-holder:before, #mega-menu-holder:after {
    content: '';
    display: table;
}
#mega-menu-holder:after {
    clear: both;
}
#mega-menu-holder.collapsed li {
    display: block;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
#mega-menu-holder.collapsed li>ul {
    position: static;
    display: none;
}
#mega-menu-holder.collapsed li a {
    display: block;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
#mega-menu-holder li .sub-toggle {
    font-family: 'font-awesome';
    position: absolute;
    right: 26px;
    top: 0;
    line-height: 75px;
    color: #8f97ab;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    z-index: 9;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
#mega-menu-holder li ul.dropdown .sub-toggle {
    top:0;
    line-height: 38px;
    right: 15px;
    display: block;
}
#mega-menu-holder ul.dropdown li.has-submenu:hover .sub-toggle {color: #fff;}
#mega-menu-holder li {position: relative;display: inline-block;vertical-align: middle;}
#mega-menu-holder>ul> li> a {
    position: relative;
    padding: 27px 35px 27px 25px;
    display: block;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    color: #8f97ab;
    -o-transition: all .3s linear;
    -moz-transition: all .3s linear;
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
}
#mega-menu-holder>ul> li.has-submenu> a {padding-right: 40px;}
#mega-menu-holder>ul> li> a:before {
    content: '/';
    line-height: 75px;
    position: absolute;
    right: 0;
    top:0;
    color: #8f97ab;
}
#mega-menu-holder>ul> li:last-child> a:before {display: none;}
#mega-menu-holder>ul> li.active > a,
#mega-menu-holder>ul> li:hover > a,
#mega-menu-holder>ul> li.active > .sub-toggle,
#mega-menu-holder>ul> li:hover > .sub-toggle {color: #fff;}
#mega-menu-holder li>ul.dropdown {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 999;
    min-width: 200px;
    white-space: nowrap;
    background: #fff;
    -webkit-box-shadow: 0px 13px 25px -12px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 13px 25px -12px rgba(0,0,0,0.25);
    box-shadow: 0px 13px 25px -12px rgba(0,0,0,0.25);
}
#mega-menu-holder li>ul.dropdown li {
    display: block;
    border-bottom: 1px solid rgba(0,0,0,0.04);
    text-align: left;
}
#mega-menu-holder li>ul.dropdown li:last-child {border:none;}
#mega-menu-holder li>ul>li ul {
    position: absolute;
    left: 100%;
    top: 0;
    z-index: 999;
    display: none;
    width: 100%;
    background: #fff;
    -webkit-box-shadow: 0px 13px 25px -12px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 13px 25px -12px rgba(0,0,0,0.25);
    box-shadow: 0px 13px 25px -12px rgba(0,0,0,0.25);
}
#mega-menu-holder ul.dropdown li a {
    width: 100%;
    background: none;
    padding: 0 0 0 15px;
    line-height: 38px;
    font-weight: 700;
    font-size: 12px;
    color:#555555;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    clear: both;
    position: relative;
    outline: 0;
    z-index: 1;
    transition-delay:0.1s;
    -o-transition: all .3s linear;
    -moz-transition: all .3s linear;
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
}
#mega-menu-holder ul.dropdown li a:before {
    content: '';
    width: 0;
    height: 100%;
    position: absolute;
    top:0;
    left:0;
    z-index: -1;
}
#mega-menu-holder ul.dropdown li:hover>a {color: #fff;}
#mega-menu-holder ul.dropdown li:hover>a:before {width: 100%;}
#mega-menu-holder>li {
    border-left: 1px solid #999;
}
#mega-menu-holder>li:first-child, #mega-menu-holder>li.has-submenu+li {
    border-left: 0;
}
@media (max-width: 991px) {
#mega-menu-holder {
    background: #fff;
    border: 1px solid rgba(0,0,0,0.05);
    max-height: 350px;
    overflow-y:auto;
    text-align: left;
    -webkit-box-shadow: 0px 13px 25px -12px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 13px 25px -12px rgba(0,0,0,0.25);
    box-shadow: 0px 13px 25px -12px rgba(0,0,0,0.25);
}
#mega-menu-holder>ul> li> a {
    width: 100%;
    padding: 0 15px;
    display: block;
    border-bottom: 1px solid rgba(0,0,0,0.05);
    margin: 0;
    line-height: 40px;
}
#mega-menu-holder li .sub-toggle {
    display: block;
    width: 50px;
    right: 0;
    line-height: 40px;
    top:0;
    border-left: solid 1px rgba(0,0,0,0.05);
}
#mega-menu-holder li ul.dropdown .sub-toggle {right: 0;}
#mega-menu-holder li>ul.dropdown {
    position: static;
    width: 100%;
}
}
/*(max-width: 991px)*/