@charset "utf-8";

#polyglotLanguageSwitcher, #polyglotLanguageSwitcher * {
	margin: 0;
	padding: 0; 
	outline: none;
}

#polyglotLanguageSwitcher ul {
	list-style: none;	
}

#polyglotLanguageSwitcher {
	color: #fff;
	line-height: normal;
	position: relative; /* sets the initial position for the drop-down menu */	
}

#polyglotLanguageSwitcher form {
	display: none;	
}

/* ---------------------------------------------------------------------- */
/* JS-created Code
/* ---------------------------------------------------------------------- */
#polyglotLanguageSwitcher a {
	text-decoration: none;
    display: block;
    padding: 5px 0px 5px 32px;
    background-repeat: no-repeat;
    background-position: 1px center;
}


#polyglotLanguageSwitcher a.current:link, #polyglotLanguageSwitcher a.current:visited, #polyglotLanguageSwitcher a.current:active {
	position: relative; /* sets the initial position for the trigger arrow */
}



#polyglotLanguageSwitcher a.active { /* This style is applied as long as the drop-down menu is visible. */
	border-bottom: none !important;
	border-radius: 3px 3px 0 0 !important;
}

#polyglotLanguageSwitcher span.trigger {
	display: block;
	position: absolute;
	top: 8px;
    right: 15px;
}


#polyglotLanguageSwitcher a.current:hover span.trigger, #polyglotLanguageSwitcher a.current:active span.trigger, #polyglotLanguageSwitcher a.active span.trigger {
	background-position: left bottom !important;
}
/* Drop-Down Menu */

#polyglotLanguageSwitcher ul.dropdown {
	background: ;
	display: none;
	position: absolute;
	top: auto;
	left: 0;
	border-top: none !important;
	border-radius: 0 0 3px 3px;
	z-index: 99999999999999999999999;
}

#polyglotLanguageSwitcher ul.dropdown li {
	width:100px;
	border-top: 1px solid #fff;
	-webkit-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
#polyglotLanguageSwitcher ul.dropdown li a {
	width:100%;
}
#polyglotLanguageSwitcher ul.dropdown li:first-child {
	border:none;
}
#polyglotLanguageSwitcher ul.dropdown li:last-child { 
	border-radius: 0 0 3px 3px;
}
/* Flags */

#en { 
	/* background-image: url(../../images/home/gb.png); */
}

#fr { 
	/* background-image: url(../../images/home/fr.png);	 */
}

#de { 
	/* background-image: url(../../images/home/de.png);	 */
}

#it { 
	/* background-image: url(../../images/home/it.png);	 */
}

#es { 
	/* background-image: url(../../images/home/es.png);	 */
}